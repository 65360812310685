$delay: 75ms;
$duration: 1.5s;
$curve: ease-out;

.typography--twist {
	font-family: 'Krona One', sans-serif;

  &--container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    transform: translateY(-10%);
  }

  &--element {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;

    @media screen and (width >= 550px)  {
      font-size: 3rem;
    }

    @for $i from 1 through 40 {
      &:nth-child(#{$i}) {
        animation-delay: ($delay * $i);

        .typography--twist--element-span {
          &::before {
            animation-delay: ($delay * $i);
          }

          &::after {
            animation-delay: ($delay * $i);
          }
        }
      }
    }
  }

  &--element-span {
    display: flex;
    position: relative;
    width: auto;
    animation: twist-element ($duration * 2) ease-in-out infinite alternate;
    color: transparent;
    line-height: 1;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
      content: attr(text);
      animation: twist-text $duration $curve infinite;
      transform-origin: left;
      color: #ffffe2;
    }

    &::after {
      animation: twist-text $duration $curve infinite reverse;
      transform-origin: right;
      color: #c82222;
    }
  }
}

@keyframes twist-text {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(90deg);
  }
}

@keyframes twist-element {
  0% {
    transform: rotateZ(-15deg);
  }

  100% {
    transform: rotateZ(15deg);
  }
}
