.player-controls--container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  display: flex;
  opacity: 0;

  .player-controls-button {
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    position: relative;

    span {
      position: relative;
      z-index: 10;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &::after {
      background-color: #000;
      z-index: 7;
      opacity: 0.7;
      animation: play-pulse-after 2000ms ease-in-out infinite;
      backdrop-filter: blur(5px);
    }

    &::before {
      border: 1rem solid rgb(255 255 255 / 50%);
      z-index: 5;
      animation: play-pulse-before 2000ms linear infinite;
    }
  }
}

@keyframes play-pulse-before {
  0% {
    border-width: 1rem;
    transform: scale(0.5);
  }

  50% {
    border-width: 1rem;
    transform: scale(1.25);
  }

  100% {
    border-width: 0;
    transform: scale(1.75);
  }
}

@keyframes play-pulse-after {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
