$delay: 75ms;
$duration: 1.5s;
$offset: 50px;
$curve: cubic-bezier(.66,.28,.29,.98);
$colors: (
  1: #ffffe2,
  2: #e77c24,
  3: #c82222,
);

.typography--ladder {
	font-family: 'Krona One', sans-serif;

  &--container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    transform: translateY(-10%);
  }

  &--element {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;

    @media screen and (width >= 550px)  {
      font-size: 3rem;
    }

    @for $i from 1 through 3 {
      &:nth-child(3n+#{$i}) {
        .typography--ladder--element-span {
          &::before,
          &::after {
            color: map-get($colors, $i);
          }
        }
      }
    }

    @for $i from 1 through 40 {
      &:nth-child(#{$i}) {
        margin-left: $offset * ($i - 10);
      }
    }
  }

  &--element-span {
    display: flex;
    position: relative;
    width: auto;
    animation: ladder-element ($duration * 3) $curve infinite;
    color: transparent;
    line-height: 1;

    &::before,
    &::after {
      position: absolute;
      display: inline-block;
      content: attr(text);
      animation: ladder-climb $duration $curve infinite;
      transform-origin: top;
    }

    &::after {
      animation: ladder-climb $duration $curve infinite reverse;
      transform-origin: bottom;
    }
  }
}

@keyframes ladder-climb {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(90deg);
  }
}

@keyframes ladder-element {
  0% {
    transform: translateX($offset * 0) translateY(0);
  }

  33% {
    transform: translateX($offset * -0.5) translateY(-100%);
  }

  66% {
    transform: translateX($offset * -1) translateY(-200%);
  }

  100% {
    transform: translateX($offset * -1.5) translateY(-300%);
  }

}
