$baseline: 20px;
$bg-color: #222;
$active-color: #c82222;
$active-bg-color: #fff;
$initial-width: 2.25rem;
$column-width: 16rem;
$rest-padding: 0.5rem;
$hamburger-layer-color: #fff;
$hamburger-padding-x: $rest-padding;
$hamburger-padding-y: $rest-padding;
$hamburger-layer-width: $initial-width - ($rest-padding * 2);
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 2px;
$bezier-curve: cubic-bezier(.41,.23,.27,1);

@import "~hamburgers/_sass/hamburgers/hamburgers";

nav.site-navigator {
  background-color: rgba($bg-color, 0.4);
  border-right: 1px solid rgba($bg-color, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: $initial-width;
  overflow: hidden;
  transition: all 500ms $bezier-curve;
  backdrop-filter: blur(10px);

  &.is-open {
    background-color: rgba($bg-color, 0.8);
    backdrop-filter: blur(20px);
    width: $column-width + $initial-width;
  }

  .logo {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: $rest-padding;
    width: $initial-width;
    display: block;
    z-index: 101;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  ul {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: $column-width;
    top: 0;
    bottom: 0;
    justify-content: center;

    &.top-level {
      left: 0;

      span {
        font-size: 1.5rem;
        font-weight: 300;
      }
    }

    li {
      padding: 0;
      margin: 0;
      width: 100%;
      opacity: 0;
      overflow: hidden;
      transform: translateX(-50px);
      transition: all 300ms $bezier-curve;

      > ul.sub-level {
        position: relative;
        display: block;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 0;

        a {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      a,
      span {
        padding: .25rem .6rem;
        line-height: 1.3;
        display: block;
        color: #fff;
        text-decoration: none;
        transition: all 150ms $bezier-curve;
        cursor: pointer;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 0;
          background-color: $active-bg-color;
          transition: all 150ms $bezier-curve;
          z-index: -1;
        }

        &:hover {
          color: $active-color;

          &::before {
            width: 100%;
          }
        }
      }

      &:hover {
        span {
          color: $active-color;

          &::before {
            width: 100%;
            background-color: $active-bg-color;
          }
        }
      }

      &.active {
        > ul.sub-level {
          height: auto;

          > li {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  }

  &.is-open {
    ul.top-level {
      > li {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @for $i from 1 to 10 {
      li:nth-child(#{$i}) {
        transition-delay: 50ms * $i;
      }
    }
  }

  .hamburger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    outline: none !important;
    cursor: pointer;
    height: 100%;
  }
}
