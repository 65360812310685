$duration: 10s;
$curve: linear;

.typography--ticker {
	font-family: 'Inter var experimental', sans-serif;
  font-size: 10rem;
  font-variation-settings: "wght" var(--var-weight), "slnt" var(--var-slant);

  &--container {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    transform: translateY(-50%);
  }

  &--element {
    display: flex;
    font-weight: 700;
    transform: rotate(-15deg);
    user-select: none;
    white-space: nowrap;

    &:nth-child(odd) {
      .typography--ticker--element-span-wrap {
        animation: ticker-element ($duration * 2) $curve infinite;
      }
    }

    &:nth-child(even) {
      .typography--ticker--element-span-wrap {
        animation: ticker-element-reverse ($duration * 2) $curve infinite;
      }
    }
  }

  &--element-span-wrap {
    display: flex;
    position: relative;
    width: max-content;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      display: inline;
      content: attr(text);
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }

  &--element-span {
    display: block;
    position: relative;
    width: auto;
    line-height: 1;
    white-space: nowrap;

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}


@keyframes ticker-element {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-33%);
  }
}

@keyframes ticker-element-reverse {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(33%);
  }
}
